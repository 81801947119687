import React from 'react'
import { Breadcrumbs, Contact, Hero, Page, Services } from '@components'

const meta = {
  title: 'Usluge | Protupožarna zaštita - FSB d.o.o.',
  description: 'Nudimo kvalitetna, sigurnosna rješenja za zaštitu ljudskih života i imovine osiguravajući svojim klijentima, inženjerima i projektantima, kompletnu podršku u izradi građevinsko – tehničkih koncepcija protupožarne zaštite.',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/usluge/', name: 'Usluge'},
      ]}
    />
    <Hero
      description={meta.description}
      subtitle="Naše usluge"
      title="Usluge"
    />
    <Services />
    <Contact />
  </Page>
)

export default Homepage
